import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter'
import isNumber from 'lodash/isNumber'

import HomeRadar from '../HomePage/HomeRadar'
import Count from '../HomePage/Count'
import Legend from '../HomePage/Legend';
import withResponsive from '../../hoc/withResponsive'

class TimeRadar extends PureComponent {
  static getDerivedStateFromProps({ answers, now }, { shape, lastTick }) {
    let tick = lastTick;
    if (now > lastTick) {
      const a = filter(answers, ({ timestamp }) => timestamp > lastTick && timestamp <= now);
      a.forEach((answer) => {
        if (isNumber(answer.real)) {
          shape.realCount += 1;
          shape.real[answer.real] += 1
        }
        shape.wantCount += 1;
        shape.want[answer.want] += 1
      })
      tick = now
    }

    return {
      lastTick: tick,
      shape,
    }
  }

  state = {
    shape: {
      realCount: 0,
      wantCount: 0,
      real: Array(12).fill(0),
      want: Array(12).fill(0),
    },
    lastTick: 0,
  }

  render() {
    const { shape } = this.state
    const { isMobile } = this.props
    return (
      <div>
        {isMobile && (
          <Legend />
        )}
        <HomeRadar shape={shape} transition={false} />
        {!isMobile && (
          <Count count={shape.wantCount} />
        )}
      </div>
    );
  }
}

TimeRadar.propTypes = {

};

export default withResponsive(TimeRadar);
