import React, { PureComponent } from 'react';
import { compose } from 'redux'
import TWEEN from '@tweenjs/tween.js'
import map from 'lodash/map'
import range from 'lodash/range'
import filter from 'lodash/filter'
import isWithinRange from 'date-fns/is_within_range'

import Box from '../../components/Box';
import Text from '../../components/Text';
import Button from '../../components/Button';
import PageStart from '../../components/PageStart';
import Page from '../Page'
import TimeRadar from './TimeRadar'
import TimeChart from './TimeChart'
import dateFormat from './dateFormat'

import { selectFirebaseData } from '../../services/firebase/selectors'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme'

const nearest = (n, ceil) => (number) => Math[ceil ? 'ceil' : 'floor'](number / n) * n

const tick = 10 * 1000 * 60;

class Replay extends PureComponent {
  state = {
    playing: false,
    min: +new Date('2018-12-23 15:30:00'),
    max: 0,
    iter: 0,
  }

  componentDidMount() {
    this.tweenPauseLastAnimateTime = 0;
    this.tweenPauseTimeSum = 0;
    requestAnimationFrame(this.animate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.answers && !prevProps.answers) {
      this.initPlayer();
    }
  }

  componentWillUnmount() {
    if (this.tween) {
      this.tween.stop()
    }
  }

  animate = (time) => {
    requestAnimationFrame(this.animate);
    const { playing } = this.state;
    if (!playing) {
      if (this.tweenPauseLastAnimateTime === 0) {
        this.tweenPauseLastAnimateTime = time;
      } else {
        this.tweenPauseTimeSum += (time - this.tweenPauseLastAnimateTime);
        this.tweenPauseLastAnimateTime = time;
      }
    } else if (this.tweenPauseLastAnimateTime !== 0) {
      this.tweenPauseLastAnimateTime = 0;
    }
    TWEEN.update(time - this.tweenPauseTimeSum);
  }

  initPlayer = () => {
    const { answers } = this.props;
    // const timestamps = map(answers, 'timestamp');
    const max = +new Date('2018-12-28 24:00')
    // const max = nearest(tick, 1)(Math.max(...timestamps))
    const data = range(this.state.min, max + tick * 2, tick * 2).map((t, i, ts) => ({
      count: filter(answers, ({ timestamp }) => isWithinRange(timestamp, ts[i-1] || 0, t)).length,
      date: t,
    }))
    this.setState({
      max,
      data,
    }, this.setPlayer)
  }

  setPlayer = () => {
    const { min, max, iter } = this.state;
    this.tween = new TWEEN.Tween({ now: min } )
      .to({ now: max }, 1000 * 20)
      .onUpdate(({ now }) => this.setState({ now }))
      .onComplete(() => {
        this.tweenPauseTimeSum = 0;
        this.tweenPauseLastAnimateTime = 0;
        setTimeout(this.setPlayer, 5000)
      })
      .start()
    this.setState({
      iter: iter + 1,
      now: min,
    })
  }

  togglePlay = () => this.setState(({ playing }) => ({ playing: !playing }))

  render() {
    const {
      playing,
      min,
      max,
      iter,
      now,
      data
    } = this.state
    const { answers } = this.props
    const progress = (now - min) / (max - min)
    const left = (
      <Box position="relative">
        <PageStart
          firstTitle="變化全紀錄"
          descrip={`紀錄期間：${iter ? dateFormat.long(min) : ''} - ${iter ? dateFormat.long(max) : ''}`}
        />
        <Box my="1em">
          <Button disabled={!iter} onClick={this.togglePlay}>{playing ? '暫停' : '播放'}</Button>
        </Box>
      </Box>
    );
    const right = (
      <Box>
        {iter && (
          <Box mx={mobileOrDesktop('5%', '10%')} mt="2em" position="relative">
            <Box position="relative">
              <TimeRadar key={iter} answers={answers} now={now} />
            </Box>
            <TimeChart data={data} progress={progress} />
            <Box position="absolute" transform="translateX(-50%)" style={{ left: `${progress * 100}%` }} whiteSpace="nowrap">
              <Text textAlign="right" color="gray" fontSize="0.9em">
                {dateFormat.short(now)}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    );
    return (
      <Page left={left} right={right} />
    );
  }
}

export default compose(
  selectFirebaseData(['answers'])
)(Replay);
