import React, { PureComponent } from 'react';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area'
// import XAxis from 'recharts/lib/cartesian/XAxis'
import { withContentRect } from 'react-measure'

import Box from '../../components/Box'
import theme from '../../components/ThemeProvider/theme'
// import dateFormat from './dateFormat'

class TimeChart extends PureComponent {
  componentDidMount() {
    this.props.measure();
  }

  render() {
    const {
      data,
      progress,
      measureRef, contentRect: { bounds: { width } },
    } = this.props;

    return (
      <Box position="relative" ref={measureRef}>
        {width && (
          <AreaChart
            width={width}
            height={width / 8}
            data={data}
            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
          >
            <Area type="monotone" dataKey="count" stroke={theme.colors.primary} fill={theme.colors.primary} />
            {/* <XAxis
              dataKey="date"
              type="number"
              tickCount={10}
              label={dateFormat.short}
            /> */}
          </AreaChart>
        )}
        <Box
          width="2px"
          top="10%"
          bottom="0"
          bg="gray"
          position="absolute"
          style={{ left: `${progress * 100}%` }}
        />
      </Box>
    );
  }
}

export default withContentRect('bounds')(TimeChart);
